@font-face {
  font-family: 'Cera Basic';
  src: url('CeraBasic-Regular.eot');
  src: url('CeraBasic-Regular.eot?#iefix') format('embedded-opentype'),
  url('CeraBasic-Regular.woff2') format('woff2'),
  url('CeraBasic-Regular.woff') format('woff'),
  url('CeraBasic-Regular.ttf') format('truetype'),
  url('CeraBasic-Regular.svg#CeraBasic-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Cormorant Unicase';
  src: url('CormorantUnicase-Semibold.eot');
  src: url('CormorantUnicase-Semibold.eot?#iefix') format('embedded-opentype'),
  url('CormorantUnicase-Semibold.woff2') format('woff2'),
  url('CormorantUnicase-Semibold.woff') format('woff'),
  url('CormorantUnicase-Semibold.ttf') format('truetype'),
  url('CormorantUnicase-Semibold.svg#CormorantUnicase-Semibold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Graphik';
  src: url('Graphik-Regular.eot');
  src: url('Graphik-Regular.eot?#iefix') format('embedded-opentype'),
  url('Graphik-Regular.woff2') format('woff2'),
  url('Graphik-Regular.woff') format('woff'),
  url('Graphik-Regular.ttf') format('truetype'),
  url('Graphik-Regular.svg#Graphik-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}



:root {
  --animate-duration: 1s;
  --animate-delay: 1s;
  --animate-repeat: 1;
}
.title {
  animation-delay: 0.5s;
}
.logo {
  animation-delay: 0.3s;
  margin-top: -30px;
}
.des {
  animation-delay: 0.7s;
}
.animated {
  animation-duration: var(--animate-duration);
  animation-fill-mode: both;
}

.animated.infinite {
  animation-iteration-count: infinite;
}

.animated.repeat-1 {
  animation-iteration-count: var(--animate-repeat);
}

.animated.repeat-2 {
  animation-iteration-count: calc(var(--animate-repeat) * 2);
}

.animated.repeat-3 {
  animation-iteration-count: calc(var(--animate-repeat) * 3);
}

.animated.delay-1s {
  animation-delay: var(--animate-delay);
}

.animated.delay-2s {
  animation-delay: calc(var(--animate-delay) * 2);
}

.animated.delay-3s {
  animation-delay: calc(var(--animate-delay) * 3);
}

.animated.delay-4s {
  animation-delay: calc(var(--animate-delay) * 4);
}

.animated.delay-5s {
  animation-delay: calc(var(--animate-delay) * 5);
}

.animated.faster {
  animation-duration: calc(var(--animate-duration) / 2);
}

.animated.fast {
  animation-duration: calc(var(--animate-duration) * 0.8);
}

.animated.slow {
  animation-duration: calc(var(--animate-duration) * 2);
}

.animated.slower {
  animation-duration: calc(var(--animate-duration) * 3);
}

@media print, (prefers-reduced-motion: reduce) {
  .animated {
    animation-duration: 1ms !important;
    transition-duration: 1ms !important;
    animation-iteration-count: 1 !important;
  }

  .animated[class*='Out'] {
    opacity: 0;
  }
}

//@keyframes zoomInDown {
//  from {
//    opacity: 0;
//    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
//    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
//  }
//
//  60% {
//    opacity: 1;
//    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
//    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
//  }
//}
//
//.zoomInDown {
//  animation-name: zoomInDown;
//}
//
//
//@keyframes zoomInUp {
//  from {
//    opacity: 0;
//    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
//    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
//  }
//
//  60% {
//    opacity: 1;
//    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
//    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
//  }
//}
//
//.zoomInUp {
//  animation-name: zoomInUp;
//}
//
//@keyframes tada {
//  from {
//    transform: scale3d(1, 1, 1);
//  }
//
//  10%,
//  20% {
//    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
//  }
//
//  30%,
//  50%,
//  70%,
//  90% {
//    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
//  }
//
//  40%,
//  60%,
//  80% {
//    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
//  }
//
//  to {
//    transform: scale3d(1, 1, 1);
//  }
//}
//
//.tada {
//  animation-name: tada;
//}
//
//@keyframes fadeIn {
//  from {
//    opacity: 0;
//  }
//
//  to {
//    opacity: 1;
//  }
//}

.fadeIn {
  animation-name: fadeIn;
}



body {
  font-family: 'Cormorant Unicase', serif;
  background-color: #2F2846;
}
.full-height {
  height: 100vh;
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  &.active {
    opacity: 1;
  }
}
.landing {
  text-align: center;
  padding:0 1rem;
  display: flex;
  flex-direction: column;
  color: #ffffff;
  position: relative;
}

.header {
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  padding-top: 20px;
  position: relative;
  z-index: 2;
}
.main {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center center;
  position: relative;
  z-index: 2;
}
.footer {
  position: relative;
  z-index: 2;
  padding-bottom: 0px;
}
.privacy {
  text-align: right;
  padding: 17px 32px 27px 32px;
  a {
    font-family: 'Graphik';
    font-size: 12px;
    line-height: 14px;
    padding: 2px;
    text-decoration: none!important;
    color: #FFFFFF;
  }
}

h2 {
  font-size: 60px;
  line-height: 73px;
  margin-bottom: 43px;
  margin-top: 22px;
}
p {
  margin-top: 0;
  margin-bottom: 1rem;
  font-weight: normal;
}
p.des {
  font-family: 'Graphik';
  font-size: 18px;
  line-height: 21px;
}
.button {
  display: inline-block;
  font-family: 'Cera Basic';
  width: 93px;
  color: #ffffff;
  font-size: 18px;
  line-height: 21px;
  text-decoration: none;
  user-select: none;
  touch-action: manipulation;
  span {
    display: block;
    &.line {
      height: 1px;
      border-bottom: 1px solid #FFFFFF;
      margin-top: 10px;
      margin-bottom: 20px;
    }
  }
}

.logo {
  width: 146px;
  img {
    width: 100%;
  }
}

.button {
  animation-delay: 0.9s;

}
.bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  pointer-events: none;
  overflow: hidden;
  iframe {
    width: 100vw;
    height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
    min-height: 100vh;
    min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media (max-width: 640px) {
  .header {
    font-weight: 600;
    font-size: 32px;
    line-height: 39px;
    padding-top: 46px;
  }

  .footer {
    padding-bottom: 35px;
  }
  .privacy {
    text-align: center;
  }
  h2 {
    font-size: 55px;
    line-height: 67px;
    margin-bottom: 101px;
    margin-top: 18px;
    span {
      display: block;
      &.sec {
        font-size: 32px;
        line-height: 39px;
      }
    }
  }
  .logo {
    width: 128px;
  }
  p.des {
    font-size: 15px;
    line-height: 18px;
  }
}

.lds-facebook {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background-color: #FBE3C9;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}
.loader {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  right:0;
  bottom:0;
  background-color: #2F2846;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes lds-facebook {
  0% {
    top: 8px;
    height: 64px;
  }
  50%, 100% {
    top: 24px;
    height: 32px;
  }
}
.video-js.vjs-fluid {
  height: 100%!important;
  padding-top: 0!important;
}

